// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require("chartkick")
require("chart.js")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "bootstrap";
import "@fortawesome/fontawesome-free/js/all";
import flatpickr from "flatpickr";

$(document).on('turbolinks:load', function() {
  var today = new Date()
  var eigthteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate())

  $('.dob-datepicker').flatpickr({
    dateFormat: 'Y-m-d',
    altFormat: 'd M Y',
    altInput: true,
    maxDate: eigthteenYearsAgo
  });

  $('.completed-at-datepicker').flatpickr({
    dateFormat: 'Y-m-d',
    altFormat: 'd M Y',
    altInput: true,
    maxDate: today
  });

  $('.status-datepicker').flatpickr({
    dateFormat: 'Y-m-d',
    altFormat: 'd M Y',
    altInput: true
  });
});
